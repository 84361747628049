<template>
    <div>
        <div>
            <Breadcrumb content="卡牌总览" />
        </div>
        <div>
            <el-button-group>
                <el-button @click="refresh" type="primary" icon="el-icon-refresh-left" title="刷新"></el-button>
                <el-button type="warning" @click="$router.push('dayCardsAdd')">新增卡牌</el-button>
                <el-button type="danger" @click="$router.push('dayCardsSet')">每日一卡</el-button>
            </el-button-group>
        </div>
        <div>
            <el-table :data="tableData" border size="small" stripe style="width: 100%">
                <el-table-column prop="created_at" label="添加时间" width="180">
                </el-table-column>
                <el-table-column prop="products.name" label="补充包">
                </el-table-column>
                <el-table-column prop="card_name" label="卡牌名称">
                </el-table-column>
                <el-table-column prop="label" label="卡牌标签">
                </el-table-column>
                <el-table-column label="卡牌图片" width="150">
                    <template slot-scope="scope">
                        <el-image style="height: 40px;" :src="scope.row.img + '?x-oss-process=image/resize,h_300,w_300'"
                            fit="contain" :preview-src-list="[scope.row.img]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column width="180" label="操作">
                    <template slot-scope="scope">

                        <el-button size="mini" type="danger" @click="handleDel(scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="padding:20px 0">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/admin/breadcrumb'
export default {
    name: "index",
    components: { Breadcrumb },
    data: function () {
        return {
            pageSize: this.$webConfig.pages.pageSize,
            pageSizes: this.$webConfig.pages.pageSizes,
            currentPage: 1,
            tableData: [],
            total: 0
        }
    },
    methods: {
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        handleDel(row) {
            let id = row.id;
            this.$confirm("是否确认删除该条数据?").then(() => {
                this.$axios.post("site/siteDayCards/del", { id })
                    .then((res) => {
                        if (res.data.code == 1000) {
                            this.$message.success("删除成功");
                            this.getList()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })

            });
        },
        getList: function () {
            let page = this.currentPage;
            let limit = this.pageSize;
            this.$axios.post("site/siteDayCards/getList", { page, limit })
                .then((res) => {
                    if (res.data.code == 1000) {
                        this.tableData = res.data.data.list;
                        this.total = res.data.data.total;
                    }
                })
        },
        refresh: function () {
            this.getList();
            this.$message.success("列表刷新成功")
        }
    },
    created() {
        this.getList();
    },
}
</script>

<style scoped></style>
